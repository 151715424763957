import { useParams } from "react-router-dom";
import NotFoundPage from "../ui/NotFoundPage";
import BSACircularProgress from "../home/widgets/BSACircularProgress";
import DOMPurify from "dompurify";
import { useSelector } from "react-redux";
import { Alert } from "@mui/material";
import { DynamicPage } from "../../store/types";

export default function DynamicPageComponent() {
  const { slug } = useParams<{ slug: string }>();
  const { data, isError, isFetching } = useSelector((state: any) => state.dynamicPages || state.publicDynamicPages);
  const page = (data || []).find((page: DynamicPage) => page.slug === slug);

  if (isFetching) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <BSACircularProgress />
      </div>
    );
  }

  if (isError) {
    return <div className="container mt-2">
      <Alert severity="error">Failed to load page.</Alert>
    </div>;
  }

  if (!page) {
    return <NotFoundPage />;
  }

  const sanitizedHTML = DOMPurify.sanitize(page.contents, {
    FORBID_TAGS: ['iframe', 'script'],
    FORBID_ATTR: ['href']
  }).replaceAll('color:rgb(255,255,255);', 'color:default;');

  return (
    <div className="container mt-2">
      <h1>{page.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
    </div>
  );
}