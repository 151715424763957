import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import BSACircularProgress from "./BSACircularProgress";
import { useSelector } from "react-redux";
import { CalendarEntry } from "../../../store/types";

export function UpcomingEventCard({ event }: { event: CalendarEntry }) {
  const sanitizedHTML = DOMPurify.sanitize(event.description, {
    FORBID_TAGS: ['iframe', 'script'],
    FORBID_ATTR: ['href']
  }).replaceAll('color:rgb(255,255,255);', 'color:default;');

  return (
    <div className="card w-100">
      <div className="card-body">
        <h5 className="card-title">{event.summary}</h5>
        <p>{describeEventTime(event)}</p>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      </div>
    </div >
  );
}

function UpcomingEventsWidget() {
  const [upcomingEvents, setUpcomingEvents] = useState<CalendarEntry[]>([]);
  const { data, isFetching, isError } = useSelector((state: any) => state.calendar);

  useEffect(() => {
    const filteredEvents = ([...data] as CalendarEntry[]).sort((a, b) => {
      return b.start - a.start;
    }).filter((event) => {
      return event.start * 1000 >= new Date().getTime();
    }).slice(0, 5);
    setUpcomingEvents(filteredEvents);
  }, [data]);

  if (isFetching) {
    return (
      <div className="card">
        <div className="card-body">
          <h1>Upcoming Events</h1>
          <div className="progress-container">
            <BSACircularProgress />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <h1>Upcoming Events</h1>
        <div className="my-3 d-flex gap-2 flex-column">
          {isError ? <Alert severity="error">Failed to fetch events.</Alert> : upcomingEvents.map((announcement) => (
            <UpcomingEventCard event={announcement} key={announcement.id} />
          ))}
          {upcomingEvents.length === 0 && <p>No upcoming events</p>}
        </div>
      </div>
    </div>
  );
}

function describeEventTime(event: CalendarEntry) {
  const startDate = new Date(event.start * 1000);
  const endDate = new Date(event.end * 1000);

  const isAllDayEvent = startDate.getUTCHours() === 0 && startDate.getUTCMinutes() === 0 &&
    endDate.getUTCHours() === 0 && endDate.getUTCMinutes() === 0 &&
    endDate.getUTCDate() === startDate.getUTCDate() + 1;

  const isOneDay = startDate.getUTCDate() === endDate.getUTCDate();

  if (isAllDayEvent) {
    if (isOneDay) {
      return `${startDate.toLocaleDateString('en-US', { timeZone: 'UTC' })}`;
    }
    return `${startDate.toLocaleDateString('en-US', { timeZone: 'UTC' })} - ${endDate.toLocaleDateString('en-US', { timeZone: 'UTC' })}`;
  }

  return `${startDate.toLocaleString('en-US', { timeZone: 'UTC' })} - ${endDate.toLocaleTimeString('en-US', { timeZone: 'UTC' })}`;
}

export default UpcomingEventsWidget;