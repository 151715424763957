import { useEffect, useState } from "react";
import { callAPI } from "../../../utils/API";
import BSACircularProgress from "../../home/widgets/BSACircularProgress";
import { Alert, Modal, Box, Typography, Button, Select, MenuItem, InputLabel, FormControl, Checkbox, ListItemText, Chip, TextField } from "@mui/material";
import { useSelector } from "react-redux";

interface User {
  id: string;
  name: string;
  email: string;
  roles: string[];
}

const predefinedRoles = [
  { value: "Admin", description: "Full permissions to view, edit, and delete all data." },
  { value: "CalendarEditor", description: "Edit and delete calendar events." },
  { value: "AnnouncementEditor", description: "Edit and delete announcements." },
  { value: "MeditBadgeEditor", description: "Edit and delete merit badges from the merit badge resource page." },
];

function UserCard({ user, onEdit }: { user: User, onEdit: (user: User) => void }) {
  return (
    <div className="col">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{user.name}</h5>
          <p className="card-text">{user.email}</p>
          <p className="card-text">
            Roles: {(user.roles || []).join(", ")}
          </p>
        </div>
        <div className="card-footer">
          <button className="btn btn-primary float-end" onClick={() => onEdit(user)}>Edit</button>
        </div>
      </div>
    </div>
  );
}

export default function ManageUsers() {
  const { data, isError, isFetching } = useSelector((state: any) => state.users);
  const users = data as User[];
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");

  const handleEdit = (user: User) => {
    setSelectedUser(user);
    setSelectedRoles(user.roles);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setSelectedUser(null);
    setSelectedRoles([]);
  };

  const handleSave = () => {
    if (selectedUser) {
      callAPI("private/tables/users", "PUT", {
        id: selectedUser.id,
        data: {
          ...selectedUser,
          roles: selectedRoles,
        }
      }).then(() => {
        handleClose();
      }).catch((error) => {
        setError(error.toString());
      });
    }
  }


  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(search.toLowerCase()) ||
    user.email.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="container mt-2">
      <h1>Users</h1>
      <TextField
        label="Search Users"
        variant="outlined"
        fullWidth
        margin="normal"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {isError && (
          <Alert severity="error" className="col">
            Failed to load users.
          </Alert>
        )}
        {filteredUsers.map((user) => (
          <UserCard key={user.id} user={user} onEdit={handleEdit} />
        ))}
        {isFetching && (
          <div className="col">
            <div className="card py-5">
              <BSACircularProgress />
            </div>
          </div>
        )}
      </div>

      <Modal open={modalOpen} onClose={handleClose}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" component="h2">
            Edit Roles for {selectedUser?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            Select the roles for this user. They may need to log out and back in for the changes to take effect.
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="roles-label">Roles</InputLabel>
            <Select
              labelId="roles-label"
              multiple
              value={selectedRoles}
              onChange={(e) => setSelectedRoles(e.target.value as string[])}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {predefinedRoles.map((role) => (
                <MenuItem key={role.value} value={role.value}>
                  <Checkbox checked={selectedRoles.indexOf(role.value) > -1} />
                  <ListItemText primary={role.value + ": " + role.description} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {error && <Alert severity="error">{error}</Alert>}
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={handleClose} color="secondary" sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button onClick={handleSave} variant="contained" color="primary">
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}