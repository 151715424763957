import { Button, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import { useNavigate, useParams } from "react-router-dom";
import BSACircularProgress from "../home/widgets/BSACircularProgress";
import { useSelector } from "react-redux";
import { CalendarEntry } from "../../store/types";
import { getUserInfo, hasRole, isAdmin } from "../../utils/API";

const userInfo = getUserInfo();

function CalendarEntryPage() {
  const { id } = useParams();
  const [event, setEvent] = useState<CalendarEntry | undefined>();
  const { data, isError, isFetching } = useSelector((state: any) => state.calendar);

  useEffect(() => {
    setEvent(data.find((entry: CalendarEntry) => entry.id === parseInt(id || "0")));
  }, [data, id]);

  const navigate = useNavigate();

  const canEdit = isAdmin(userInfo) || hasRole(userInfo, "CalendarAdmin");

  if (isError) {
    return (
      <div className="container mt-2">
        <Alert severity="error">Failed to load events.</Alert>
      </div>
    );
  }

  if (isFetching) {
    return (
      <div className="d-flex justify-content-center align-items-center py-5">
        <BSACircularProgress />
      </div>
    );
  }

  if (!event) {
    return (
      <div className="container mt-2">
        <Alert severity="error">Event not found.</Alert>
      </div>
    );
  }

  const starts = new Date(event.start * 1000);
  const ends = new Date(event.end * 1000);
  const sanitizedHTML = DOMPurify.sanitize(event.description, {
    FORBID_TAGS: ['iframe', 'script'],
    FORBID_ATTR: ['href']
  }).replaceAll('color:rgb(255,255,255);', 'color:default;');

  return (
    <div className="container mt-4">
      <Button variant="outlined" className="mb-2 me-2" onClick={() => navigate("/calendar")}>Back to Calendar</Button>
      {canEdit && (
        <Button variant="outlined" className="mb-2 me-2" onClick={() => navigate(`/calendar/events/${event.id}/edit`)}>Edit</Button>
      )}
      <div className="card shadow-sm">
        <div className="card-body">
          <h1 className="card-title border-bottom mb-3 pb-1">{event.summary}</h1>
          <div className="card-text" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
          <p className="card-text"><strong>Location:</strong> {event.location}</p>
          <p className="card-text"><strong>Time:</strong> {starts.toLocaleString()} - {ends.toLocaleString()}</p>
          {event.signup_enabled && (
            <div className="d-grid">
              <Button variant="outlined">Sign Up</Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CalendarEntryPage;