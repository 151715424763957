import { Button, MenuItem, TextField } from "@mui/material";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { callAPI } from "../../../utils/API";

const CreateDynamicPage: React.FC = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [contents, setContents] = useState("");
  const [visibility, setVisibility] = useState("public");
  const [saving, setSaving] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);
    const sanitizedContents = DOMPurify.sanitize(contents, {
      FORBID_TAGS: ["iframe", "script"],
      FORBID_ATTR: ["href"],
    }).replaceAll('color:rgb(255,255,255);', 'color:default;');
    callAPI(`private/tables/dynamic_pages`, "POST",
      {
        title,
        slug,
        contents: sanitizedContents,
        visibility,
      })
      .then(() => {
        setSaving(false);
        navigate("/admin/pages");
      })
      .catch(() => {
        setSaving(false);
        // Handle error if needed
      });
  };

  return (
    <div className="container mt-2">
      <h1>Add Dynamic Page</h1>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-column gap-2">
          <TextField
            label="Page Name"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <TextField
            label="Slug"
            fullWidth
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            required
          />
          <TextField
            label="Visibility"
            select
            fullWidth
            value={visibility}
            onChange={(e) => setVisibility(e.target.value)}
            required
          >
            <MenuItem value="public">Public: Allow anyone to read</MenuItem>
            <MenuItem value="private">Private: Require login before reading</MenuItem>
          </TextField>
          <CKEditor
            editor={ClassicEditor}
            data={contents}
            onReady={editor => {
              // You can store the "editor" and use when it is needed.
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setContents(data);
            }}
            onBlur={(event, editor) => {
              console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              console.log('Focus.', editor);
            }}
          />
          <div className="d-flex justify-content-end gap-2">
            <Button variant="contained" component={Link} to="/admin/pages">Cancel</Button>
            <Button variant="contained" type="submit" disabled={saving}>
              {saving ? "Saving..." : "Save"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateDynamicPage;