import FullCalendar from "@fullcalendar/react";
import { useEffect, useState } from "react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import "./Calendar.css";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { CalendarEntry } from "../../store/types";
import { EventInput } from "@fullcalendar/core";
import { getUserInfo, hasRole, isAdmin } from "../../utils/API";

const formatDate = (date: Date) => {
  const datePart = date.toLocaleDateString('en-CA', { timeZone: 'UTC' }); // 'en-CA' gives YYYY-MM-DD format
  const timePart = date.toLocaleTimeString('en-GB', { timeZone: 'UTC', hour12: false }); // 'en-GB' gives HH:MM:SS format
  return `${datePart} ${timePart}`;
};

const userInfo = getUserInfo();

const CalendarPage = () => {
  const [events, setEvents] = useState<EventInput[]>([]);
  const navigate = useNavigate();
  const { data, isError, isFetching } = useSelector((state: any) => state.calendar);

  useEffect(() => {
    const newEvents: EventInput[] = data.map((entry: CalendarEntry) => {
      const startDate = new Date(entry.start * 1000);
      const endDate = new Date(entry.end * 1000);
      return {
        id: String(entry.id),
        title: entry.summary,
        start: formatDate(startDate),
        end: formatDate(endDate),
      };
    });
    setEvents(newEvents);
  }, [data]);

  const canEdit = isAdmin(userInfo) || hasRole(userInfo, "CalendarAdmin");

  return (
    <div className="container mt-2">
      {isError && <Alert severity="error">Failed to load events.</Alert>}
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        weekends={false}
        events={events}
        dateClick={(info) => {
          if (canEdit)
            navigate(`/calendar/events/add?date=${info.dateStr}`);
        }}
        eventClick={(info) => {
          navigate(`/calendar/events/${info.event.id}`);
        }}
      // eventContent={renderEventContent}
      />
    </div>
  );
};

export default CalendarPage;