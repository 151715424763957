import { Alert, Button, MenuItem, TextField } from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { callAPI } from "../../utils/API";
import BSACircularProgress from "../home/widgets/BSACircularProgress";
import dayjs from 'dayjs';
import { CalendarEntry } from "../../store/types";
import { useSelector } from "react-redux";


const EditCalendarEventPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [editorData, setEditorData] = useState("");
  const [eventName, setEventName] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [location, setLocation] = useState("");
  const [url, setUrl] = useState("");
  const [featured, setFeatured] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const calendar = useSelector((state: any) => state.calendar.data as CalendarEntry[]);
  const loading = useSelector((state: any) => state.calendar.isFetching as boolean);

  useEffect(() => {
    const result = calendar.find((event: CalendarEntry) => event.id === Number(id));
    if (!result) {
      return;
    }
    setEventName(result.summary);
    setEditorData(result.description);
    setStartDate(new Date(result.start * 1000));
    setEndDate(new Date(result.end * 1000));
    setLocation(result.location);
    setUrl(result.url);
    setFeatured(result.featured);
  }, [id, calendar]);

  console.log(startDate, endDate);

  const handleSave = () => {
    if (!startDate || !endDate || !eventName) return;
    const updatedEvent: CalendarEntry = {
      id: Number(id),
      summary: eventName,
      description: editorData,
      start: startDate.getTime() / 1000,
      end: endDate.getTime() / 1000,
      location,
      featured,
      url,
      signup_enabled: false,
    };

    callAPI(`/private/tables/calendar`, "PUT", {
      id: Number(id),
      data: updatedEvent
    })
      .then(() => {
        navigate("/calendar");
      })
      .catch((err) => {
        setError(err.toString());
      });
  };

  if (loading) {
    return (
      <div className="container mt-2">
        <h1>Edit Calendar Event</h1>
        <div className="progress-container card mt-2 py-5">
          <BSACircularProgress />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-2">
        <h1>Edit Calendar Event</h1>
        <div className="progress-container card mt-2 py-5">
          <Alert severity="error">{error}</Alert>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-2">
      <h1>Edit Calendar Event</h1>
      <div className="d-flex flex-column gap-2">
        <TextField
          label="Event Name"
          fullWidth
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
        />
        <DateTimePicker
          label="Start Date"
          value={dayjs(startDate)}
          onChange={(date) => setStartDate(date?.toDate() || null)}
        />
        <DateTimePicker
          label="End Date"
          value={dayjs(endDate)}
          onChange={(date) => setEndDate(date?.toDate() || null)}
        />
        <TextField
          label="Location"
          fullWidth
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
        <TextField
          label="URL"
          fullWidth
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <TextField
          label="Visibility"
          select
          fullWidth
          value={featured}
          onChange={(e) => setFeatured(e.target.value === 'public')}
        >
          <MenuItem value="public">Feature on homepage when not logged in</MenuItem>
          <MenuItem value="private">Do not feature on homepage when not logged in</MenuItem>
        </TextField>
        <CKEditor
          editor={ClassicEditor}
          data={editorData}
          onReady={editor => {
            // You can store the "editor" and use when it is needed.
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditorData(data);
            console.log({ event, editor, data });
          }}
          onBlur={(event, editor) => {
            console.log('Blur.', editor);
          }}
          onFocus={(event, editor) => {
            console.log('Focus.', editor);
          }}
        />
        <div className="d-flex justify-content-end gap-2">
          <Button variant="contained" component={Link} to="/calendar">Cancel</Button>
          <Button variant="contained" onClick={handleSave}>Save</Button>
        </div>
      </div>
    </div>
  );
}

export default EditCalendarEventPage;