import { Alert } from "@mui/material";
import { AnnouncementCard } from "../../announcements/AnnouncementsPage";
import BSACircularProgress from "./BSACircularProgress";
import { useSelector } from "react-redux";
import { Announcement } from "../../../store/types";

function AnnouncementsWidget() {
  const { data, isError, isFetching } = useSelector((state: any) => state.announcements);

  const sortedAnnouncements = (data as Announcement[]).sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  if (isFetching) {
    return (
      <div className="card">
        <div className="card-body">
          <h1>Announcements</h1>
          <div className="progress-container">
            <BSACircularProgress />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <h1>Announcements</h1>
        <div className="my-3 d-flex gap-2 flex-column">
          {isError ? <Alert severity="error">Failed to fetch announcements.</Alert> : sortedAnnouncements.map((announcement) => (
            <AnnouncementCard announcement={announcement} key={announcement.id} />
          ))}
          {sortedAnnouncements.length === 0 && <p>There aren't any announcements yet. Check back later.</p>}
        </div>
      </div>
    </div>
  );
}

export default AnnouncementsWidget;