import { Link, useParams } from "react-router-dom"; // import useParams
import { TROOP_DOMAIN } from "../../../utils/config";
import DOMPurify from "dompurify";
import BSACircularProgress from "../../home/widgets/BSACircularProgress";
import { MeritBadge } from "../../../store/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Alert, Button } from "@mui/material";

function MeritBadgeDetailsPage() {
  const { id } = useParams<{ id: string }>();
  const [meritBadge, setMeritBadge] = useState<MeritBadge | undefined>();
  const { data, isError, isFetching } = useSelector((state: any) => state.meritBadges);

  useEffect(() => {
    setMeritBadge(data.find((mb: MeritBadge) => mb.name.replace(/ /g, "-").toLowerCase() === id));
  }, [data, id]);

  if (isError) {
    return (
      <div className="container mt-2">
        <Link to="/resources/merit-badges" className="text-white text-decoration-none">
          <h1>Merit Badges</h1>
        </Link>
        <div className="card mt-2">
          <Alert severity="error">Failed to fetch merit badge.</Alert>
        </div>
      </div>
    );
  }

  if (isFetching) {
    return (
      <div className="container mt-2">
        <Link to="/resources/merit-badges" className="text-white text-decoration-none">
          <h1>Merit Badges</h1>
        </Link>
        <div className="card progress-container py-5">
          <BSACircularProgress />
        </div>
      </div>
    );
  }

  if (!meritBadge) {
    return (
      <div className="container mt-2">
        <Link to="/resources/merit-badges" className="text-decoration-none">
          <h1>Merit Badges</h1>
        </Link>
        <div className="card mt-2">
          <h1>Merit Badge Details</h1>
          <p>Merit badge not found.</p>
        </div>
      </div>
    );
  }

  const sanitizedHTML = DOMPurify.sanitize(meritBadge.requirements, {
    FORBID_TAGS: ['iframe', 'script'],
    FORBID_ATTR: ['href']
  }).replaceAll('color:rgb(255,255,255);', 'color:default;');

  return (
    <div className="container mt-2">
      <Link to="/resources/merit-badges" className="text-white text-decoration-none">
        <h1>Merit Badges</h1>
      </Link>
      <div className="card mt-2">
        <div className="card-header d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img src={`https://${TROOP_DOMAIN}/${meritBadge.img}`} alt={meritBadge.name} className="badge-img me-2" />
            <h1 className="badge-name mt-2">{meritBadge.name}</h1>
          </div>
          <Button variant="contained" disabled={!meritBadge.workbook_url} color="primary" href={meritBadge.workbook_url} target="_blank" rel="noreferrer">
            Workbook
          </Button>
        </div>
        <div className="card-body">
          <p>Eagle Required: {meritBadge.eagle_required ? "Yes" : "No"}</p>
          <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
        </div>
      </div>
    </div>
  );
}

export default MeritBadgeDetailsPage;