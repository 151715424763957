import { useState } from "react";
import { callAPI } from "../../../utils/API";
import BSACircularProgress from "../../home/widgets/BSACircularProgress";
import { Alert, Modal, Box, TextField, Button } from "@mui/material";
import { useSelector } from "react-redux";

interface HomePageSection {
  id: number;
  title: string;
  heroText: string;
  description: string;
  imageUrl: string;
  action: {
    text: string;
    link: string;
  };
}

function HomePageSectionCard({ section, onEdit, onDelete }: { section: HomePageSection, onEdit: (section: HomePageSection) => void, onDelete: (section: HomePageSection) => void }) {
  return (
    <div className="col">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{section.title}</h5>
          <p className="card-text">{section.description}</p>
          <div className="d-flex gap-2 justify-content-end">
            <button className="btn btn-outline-danger" onClick={() => onDelete(section)}>Delete</button>
            <button className="btn btn-outline-primary" onClick={() => onEdit(section)}>Edit</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ManageHomePage() {
  const { data, isError, isFetching } = useSelector((state: any) => state.homePageSections);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [currentSection, setCurrentSection] = useState<HomePageSection | null>(null);
  const [formData, setFormData] = useState({
    id: 0,
    title: "",
    heroText: "",
    description: "",
    imageUrl: "",
    action: {
      text: "",
      link: ""
    }
  });
  const sections = data as HomePageSection[];

  const handleOpenCreateModal = () => {
    const newId = (sections.length > 0 ? Math.max(...sections.map((section) => section.id)) : 0) + 1;
    setFormData({
      id: newId,
      title: "",
      heroText: "",
      description: "",
      imageUrl: "",
      action: {
        text: "",
        link: ""
      }
    });
    setIsCreateModalOpen(true);
  };

  const handleOpenEditModal = (section: HomePageSection) => {
    setFormData({
      id: section.id,
      title: section.title,
      heroText: section.heroText,
      description: section.description,
      imageUrl: section.imageUrl,
      action: {
        text: section.action.text,
        link: section.action.link
      }
    });
    setCurrentSection(section);
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsCreateModalOpen(false);
    setIsEditModalOpen(false);
    setCurrentSection(null);
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateSection = () => {
    callAPI("private/tables/home_page_sections", "POST", formData)
      .then((result) => {
        handleCloseModal();
      })
      .catch((error) => {
        setError(error.toString());
      });
    handleCloseModal();
  };

  const handleEditSection = () => {
    if (!currentSection) return;
    callAPI("private/tables/home_page_sections", "PUT", {
      id: formData.id,
      data: formData
    })
      .then((result) => {
        handleCloseModal();
      })
      .catch((error) => {
        setError(error.toString());
      });
    handleCloseModal();
  };

  const handleDeleteSection = (section: HomePageSection) => {
    if (!window.confirm("Are you sure you want to delete this section?")) return;
    callAPI("private/tables/home_page_sections", "DELETE", {
      id: section.id
    })
      .catch((error) => {
        setError(error.toString());
      });
  };

  return (
    <div className="container mt-2">
      <h1>Home Page Sections</h1>
      {isFetching ? (
        <div className="d-flex justify-content-center mt-5">
          <BSACircularProgress />
        </div>
      ) : (
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          <div className="col">
            <div className="card">
              <div className="card-header">Create New Section</div>
              <div className="card-body">
                <button className="btn btn-outline-primary" onClick={handleOpenCreateModal}>Create</button>
              </div>
            </div>
          </div>
          {sections.map((section) => (
            <HomePageSectionCard
              key={section.id}
              section={section}
              onEdit={handleOpenEditModal}
              onDelete={handleDeleteSection}
            />
          ))}
          {isError && (
            <Alert severity="error" className="col">
              Failed to load sections.
            </Alert>
          )}
          {error && (
            <Alert severity="error" className="col">
              {error}
            </Alert>
          )}
        </div>
      )}

      <Modal open={isCreateModalOpen} onClose={handleCloseModal}>
        <Box sx={{ ...modalStyle }}>
          <h2>Create Section</h2>
          <TextField label="Title" name="title" value={formData.title} onChange={handleFormChange} fullWidth margin="normal" />
          <TextField label="Hero Text" name="heroText" value={formData.heroText} onChange={handleFormChange} fullWidth margin="normal" />
          <TextField label="Description" name="description" value={formData.description} onChange={handleFormChange} fullWidth margin="normal" />
          <TextField label="Image URL" name="imageUrl" value={formData.imageUrl} onChange={handleFormChange} fullWidth margin="normal" />
          <TextField label="Button Text" name="actionText" value={formData.action.text} onChange={handleFormChange} fullWidth margin="normal" />
          <TextField label="Button Link" name="actionLink" value={formData.action.link} onChange={handleFormChange} fullWidth margin="normal" />
          <Button variant="contained" color="primary" onClick={handleCreateSection}>Create</Button>
        </Box>
      </Modal>

      <Modal open={isEditModalOpen} onClose={handleCloseModal}>
        <Box sx={{ ...modalStyle }}>
          <h2>Edit Section</h2>
          <TextField label="Title" name="title" value={formData.title} onChange={handleFormChange} fullWidth margin="normal" />
          <TextField label="Hero Text" name="heroText" value={formData.heroText} onChange={handleFormChange} fullWidth margin="normal" />
          <TextField label="Description" name="description" value={formData.description} onChange={handleFormChange} fullWidth margin="normal" />
          <TextField label="Image URL" name="imageUrl" value={formData.imageUrl} onChange={handleFormChange} fullWidth margin="normal" />
          <TextField label="Button Text" name="actionText" value={formData.action.text} onChange={handleFormChange} fullWidth margin="normal" />
          <TextField label="Button Link" name="actionLink" value={formData.action.link} onChange={handleFormChange} fullWidth margin="normal" />
          <Button variant="contained" color="primary" onClick={handleEditSection}>Save</Button>
        </Box>
      </Modal>
    </div>
  );
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};