import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";

// Generic function to create async thunk
const createFetchThunk = (name: string) => {
  return createAsyncThunk(`${name}/fetch`, async (_, { rejectWithValue }) => {
    try {
      const jwt = localStorage.getItem("jwt");
      const response = await axios.get(`${API_URL}/private/tables/${name}`, {
        headers: {
          Authorization: jwt,
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  });
};

const createPublicFetchThunk = (name: string) => {
  return createAsyncThunk(`${name}/fetch`, async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/public/${name}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  });
};

// Generic function to create slice
const createGenericSlice = (name: string, fetchThunk: any) => {
  return createSlice({
    name,
    initialState: {
      data: [],
      isFetching: false,
      isSuccess: false,
      isError: false,
      errorMessage: "",
    },
    reducers: {
      clearState: (state) => {
        state.isError = false;
        state.isSuccess = false;
        state.errorMessage = "";
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchThunk.pending, (state) => {
          state.isFetching = true;
          state.isError = false;
          state.isSuccess = false;
          state.errorMessage = "";
        })
        .addCase(fetchThunk.fulfilled, (state, action) => {
          state.isFetching = false;
          state.isSuccess = true;
          state.data = action.payload;
        })
        .addCase(fetchThunk.rejected, (state, action) => {
          state.isFetching = false;
          state.isError = true;
          state.errorMessage = action.payload as string;
        });
    },
  });
};

// Create thunks for responses and houses
export const fetchCalendar = createFetchThunk("calendar");
export const fetchAnnouncements = createFetchThunk("announcements");
export const fetchDynamicPages = createFetchThunk("dynamic_pages");
export const fetchMeritBadges = createFetchThunk("merit_badges");
export const fetchPhotoAlbums = createFetchThunk("photo_albums");
export const fetchHomePageSections = createFetchThunk("home_page_sections");
export const fetchPublicDynamicPages = createPublicFetchThunk("pages");
export const fetchPublicHomePageSections = createPublicFetchThunk("home-page");
export const fetchPublicUpcomingEvent = createPublicFetchThunk("upcoming-events");

// Create slices for responses and houses
export const calendarSlice = createGenericSlice("calendar", fetchCalendar);
export const announcementsSlice = createGenericSlice("announcements", fetchAnnouncements);
export const dynamicPagesSlice = createGenericSlice("dynamic_pages", fetchDynamicPages);
export const meritBadgesSlice = createGenericSlice("merit_badges", fetchMeritBadges);
export const photoAlbumsSlice = createGenericSlice("photo_albums", fetchPhotoAlbums);
export const homePageSectionsSlice = createGenericSlice("home_page_sections", fetchHomePageSections);
export const publicDynamicPagesSlice = createGenericSlice("dynamic_pages", fetchPublicDynamicPages);
export const publicHomePageSectionsSlice = createGenericSlice("home_page_sections", fetchPublicHomePageSections);
export const publicUpcomingEventSlice = createGenericSlice("calendar", fetchPublicUpcomingEvent);

export const calendarReducer = calendarSlice.reducer;
export const announcementsReducer = announcementsSlice.reducer;
export const dynamicPagesReducer = dynamicPagesSlice.reducer;
export const meritBadgesReducer = meritBadgesSlice.reducer;
export const photoAlbumsReducer = photoAlbumsSlice.reducer;
export const homePageSectionsReducer = homePageSectionsSlice.reducer;
export const publicDynamicPagesReducer = publicDynamicPagesSlice.reducer;
export const publicHomePageSectionsReducer = publicHomePageSectionsSlice.reducer;
export const publicUpcomingEventReducer = publicUpcomingEventSlice.reducer;
