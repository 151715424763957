import { configureStore } from "@reduxjs/toolkit";
import { announcementsReducer, calendarReducer, dynamicPagesReducer, homePageSectionsReducer, meritBadgesReducer, photoAlbumsReducer, publicDynamicPagesReducer, publicHomePageSectionsReducer, publicUpcomingEventReducer } from "./slices";

const store = configureStore({
  reducer: {
    calendar: calendarReducer,
    announcements: announcementsReducer,
    dynamicPages: dynamicPagesReducer,
    meritBadges: meritBadgesReducer,
    homePageSections: homePageSectionsReducer,
    photoAlbums: photoAlbumsReducer,
    publicDynamicPages: publicDynamicPagesReducer,
    publicHomePageSections: publicHomePageSectionsReducer,
    publicUpcomingEvent: publicUpcomingEventReducer,
  },
});

export default store;
