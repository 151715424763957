import { useState, useEffect } from "react";
import { callAPI } from "../../../utils/API";
import { Alert, TextField } from "@mui/material";

interface Video {
  id: string;
  name: string;
  youtube_id: string;
}

function VideoCard({ Video }: { Video: Video }) {
  return (
    <div className="card" style={{ width: "100%", maxWidth: "250px" }}>
      <div className="card-body">
        <h5 className="card-title">{Video.name}</h5>
        <iframe
          width="100%"
          height="200"
          src={`https://www.youtube.com/embed/${Video.youtube_id}`}
          title={Video.name}
          allowFullScreen
        ></iframe>
      </div>
    </div >
  );
}

function VideosPage() {
  const [videos, setVideos] = useState<Video[]>([]);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    callAPI("private/tables/videos")
      .then((result) => {
        setVideos(result);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  const filteredVideos = videos.filter((Video) =>
    Video.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (error) {
    return (
      <div className="container mt-2">
        <h1>Videos</h1>
        <Alert severity="error">{error}</Alert>
      </div>
    );
  }

  return (
    <div className="container mt-2">
      <h1>Videos</h1>
      <TextField
        label="Search Videos"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="my-3 d-flex gap-2 flex-wrap">
        {filteredVideos.map((Video) => (
          <VideoCard Video={Video} key={Video.id} />
        ))}
      </div>
    </div>
  );
}

export default VideosPage;