import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { callAPI } from "../../../utils/API";
import DOMPurify from "dompurify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BSACircularProgress from "../../home/widgets/BSACircularProgress";

interface DynamicPage {
  title: string;
  contents: string;
  slug: string;
  public: boolean;
}

export default function EditDynamicPage() {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const [page, setPage] = useState<DynamicPage | null>(null);
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchPage = async () => {
      setLoading(true);
      const results = await callAPI(`private/tables/dynamic_pages`);
      const result = results.find((page: DynamicPage) => page.slug === slug);
      setPage(result);
      setTitle(result?.title);
      setContents(result?.contents);
      setLoading(false);
    };

    fetchPage();
  }, [slug]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);
    const sanitizedContents = DOMPurify.sanitize(contents, {
      FORBID_TAGS: ["iframe", "script"],
      FORBID_ATTR: ["href"],
    }).replaceAll('color:rgb(255,255,255);', 'color:default;');

    callAPI(`private/tables/dynamic_pages`, "POST", {
      id: slug,
      data: {
        ...page,
        contents: sanitizedContents,
      }
    })
      .then(() => {
        setSaving(false);
        navigate("/admin/pages");
      })
      .catch(() => {
        setSaving(false);
        // Handle error if needed
      });
  };

  const handleDelete = () => {
    if (!window.confirm("Are you sure you want to delete this page?")) {
      return;
    }
    setSaving(true);
    callAPI(`private/tables/dynamic_pages`, "DELETE", {
      id: slug
    })
      .then(() => {
        setSaving(false);
        navigate("/admin/pages");
      })
      .catch(() => {
        setSaving(false);
        // Handle error if needed
      });
  };

  if (loading) {
    return (
      <div className="container mt-5 d-flex justify-content-center">
        <BSACircularProgress />
      </div>
    );
  }

  if (!page) {
    return (
      <div className="container mt-2">
        <h1>Page not found</h1>
        <Link to="/admin/pages" className="btn btn-outline-secondary">Back</Link>
      </div>
    );
  }

  return (
    <div className="container mt-2">
      <h1>Edit Page</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="contents" className="form-label">Contents</label>
          <CKEditor
            editor={ClassicEditor}
            data={contents}
            onReady={editor => {
              // You can store the "editor" and use when it is needed.
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setContents(data);
            }}
            onBlur={(event, editor) => {
              console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              console.log('Focus.', editor);
            }}
          />
        </div>
        <button type="submit" className="btn btn-outline-primary" disabled={saving}>
          {saving ? "Saving..." : "Save"}
        </button>
        <button type="button" className="btn btn-outline-danger ms-2" onClick={handleDelete} disabled={saving}>
          {saving ? "Deleting..." : "Delete"}
        </button>
        <Link to="/admin/pages" className="btn btn-outline-secondary ms-2">Cancel</Link>
      </form>
    </div>
  );
}