import { useState, useEffect } from "react";
import { Alert, Button, TextField } from "@mui/material";
import { TROOP_DOMAIN } from "../../../utils/config";
import { useNavigate } from "react-router-dom";
import BSACircularProgress from "../../home/widgets/BSACircularProgress";
import { useSelector } from "react-redux";
import { MeritBadge } from "../../../store/types";

function MeritBadgeCard({ meritBadge }: { meritBadge: MeritBadge }) {
  const navigate = useNavigate();

  return (
    <div className="card" style={{ width: "100%", maxWidth: "250px" }}>
      <img src={`https://${TROOP_DOMAIN}/${meritBadge.img}`} alt={meritBadge.name} height="200" width="200" />
      <div className="card-body">
        <h5 className="card-title">{meritBadge.name}</h5>
        <Button variant="contained" color="primary" onClick={() => {
          navigate(`/resources/merit-badges/${meritBadge.name.replace(/ /g, "-").toLowerCase()}`);
        }}>
          View Details
        </Button>
      </div>
    </div >
  );
}

function MeritBadgesPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMeritBadges, setFilteredMeritBadges] = useState<MeritBadge[]>([]);
  const { data, isError, isFetching } = useSelector((state: any) => state.meritBadges);

  useEffect(() => {
    const badges = (data as MeritBadge[]).filter((meritBadge) =>
      meritBadge.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMeritBadges(badges);
  }, [data, searchTerm]);

  if (isError) {
    return (
      <div className="container mt-2">
        <h1>Merit Badges</h1>
        <Alert severity="error">Failed to fetch merit badges.</Alert>
      </div>
    );
  }

  if (isFetching) {
    return (
      <div className="container mt-2">
        <h1>Merit Badges</h1>
        <TextField
          label="Search Merit Badges"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="card progress-container py-5 mt-2">
          <BSACircularProgress />
        </div>
      </div >
    );
  }

  return (
    <div className="container mt-2">
      <h1>Merit Badges</h1>
      <TextField
        label="Search Merit Badges"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="my-3 d-flex gap-2 flex-wrap">
        {filteredMeritBadges.map((meritBadge) => (
          <MeritBadgeCard meritBadge={meritBadge} key={meritBadge.name} />
        ))}
      </div>
    </div>
  );
}

export default MeritBadgesPage;