import { Box, CircularProgress } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import { ReactComponent as BSALogo } from "../../../assets/img/bsa.svg";

// Define the keyframes for the pulsing animation
const pulse = keyframes({
  '0%': {
    transform: 'scale(1)',
  },
  '50%': {
    transform: 'scale(1.1)',
  },
  '100%': {
    transform: 'scale(1)',
  },
});

const Container = styled(Box)({
  position: "relative",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledBSALogo = styled(BSALogo)({
  position: "absolute",
  width: 60,
  height: 60,
  animation: `${pulse} 2s infinite ease-in-out`, // Apply the animation
});

const CustomCircularProgress: React.FC = ({ ...props }) => {
  return (
    <Container>
      <CircularProgress size={100} {...props} />
      <StyledBSALogo />
    </Container>
  );
};

export default CustomCircularProgress;