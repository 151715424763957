import { useState } from "react";
import { callAPI, getUserInfo } from "../../utils/API";
import { Alert, Button, Modal, Paper, TextField } from "@mui/material";
import DOMPurify from "dompurify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BSACircularProgress from "../home/widgets/BSACircularProgress";
import { useSelector } from "react-redux";
import { Announcement } from "../../store/types";

export function AnnouncementCard({ announcement }: { announcement: Announcement }) {
  const sanitizedHTML = DOMPurify.sanitize(announcement.content, {
    FORBID_TAGS: ['iframe', 'script'],
    FORBID_ATTR: ['href']
  }).replaceAll('color:rgb(255,255,255);', 'color:default;');

  const handleDelete = () => {
    callAPI("private/tables/announcements", "DELETE", { id: announcement.id })
      .then(() => {
        // TODO: update ui??
      })
      .catch((err) => {
        alert("Failed to delete announcement: " + err.message);
      });
  };

  return (
    <div className="card w-100">
      <div className="card-body">
        {announcement.created_by === getUserInfo().sub && (
          <span className="card-delete" title="Delete" onClick={handleDelete}>&times;</span>
        )}
        <h5 className="card-title">{announcement.title}</h5>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      </div>
    </div >
  );
}

function AnnouncementsPage() {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newContent, setNewContent] = useState("");
  const { data, isError, isFetching } = useSelector((state: any) => state.announcements);
  const [addError, setError] = useState("");

  const sortedAnnouncements = (data as Announcement[]).sort((a, b) => {
    return new Date(b.created_at * 1000).getTime() - new Date(a.created_at * 1000).getTime();
  });

  const handleAddAnnouncement = () => {
    const newAnnouncement = {
      id: Date.now(),
      title: newTitle,
      content: newContent,
    };

    callAPI("private/tables/announcements", "POST", newAnnouncement)
      .then((result) => {
        setAddModalOpen(false);
        setNewTitle("");
        setNewContent("");
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  if (isFetching) {
    return (
      <div className="container mt-2">
        <h1>Announcements</h1>
        <div className="progress-container card mt-2 py-5">
          <BSACircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-2">
      <h1>Announcements</h1>
      <Button onClick={() => setAddModalOpen(true)} variant="outlined">Add New</Button>
      <div className="my-3 d-flex gap-2 flex-column">
        {isError ? <Alert severity="error">Failed to fetch announcements.</Alert> : sortedAnnouncements.map((a) => (
          <AnnouncementCard announcement={data} key={a.id} />
        ))}
      </div>
      <Modal open={addModalOpen} onClose={() => setAddModalOpen(false)}>
        <Paper
          className="modal-content"
          style={{
            padding: '20px',
            maxWidth: '800px',
            margin: '10% auto',
            position: 'relative',
          }}
        >
          <div className="d-flex flex-column gap-2">
            <h2>Add New Announcement</h2>
            <TextField
              label="Title"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              fullWidth
            />
            <CKEditor
              editor={ClassicEditor}
              data=""
              onReady={editor => {
                // You can store the "editor" and use when it is needed.
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setNewContent(data);
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
            />
            {addError && <Alert severity="error">Failed to add announcement.</Alert>}
            <Button onClick={handleAddAnnouncement} variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}

export default AnnouncementsPage;