import { API_URL } from "./config";

export function callAPI(
  endpoint: string,
  method = "GET",
  body: any = undefined
): Promise<any> {
  if (endpoint.startsWith("/")) {
    endpoint = endpoint.substring(1);
  }
  if (!navigator.onLine) {
    return new Promise((resolve, reject) => {
      if (method === "GET") {
        getFromLocalStorage(endpoint).then((data: any) => {
          resolve(data);
        });
      } else {
        reject("You are offline, please try again later.");
      }
    });
  }
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = localStorage.getItem("jwt");
  if (jwt) {
    headers.append("Authorization", jwt);
  }
  if (body instanceof FormData) {
    headers.delete("Content-Type");
  }
  return fetch(API_URL + endpoint, {
    method: method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
    mode: "cors",
  })
    .then((response) => {
      if (response.status === 401) {
        window.location.href = `${API_URL}auth/google`;
        return;
      }
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then((data) => {
      saveInLocalStorage(endpoint, data);
      return data;
    });
}

export function unCacheAPI(endpoint: string) {
  if (navigator.onLine) {
    localStorage.removeItem(endpoint);
  }
}

function saveInLocalStorage(endpoint: string, data: any) {
  localStorage.setItem(
    endpoint,
    JSON.stringify({ data, timestamp: Date.now() })
  );
}

function getFromLocalStorage(endpoint: string): Promise<any> {
  return new Promise((resolve, reject) => {
    const data = localStorage.getItem(endpoint);
    if (data) {
      let parsedData = JSON.parse(data);
      if (parsedData && parsedData.data) {
        resolve(parsedData.data);
      }
      reject("No data found in local storage.");
    }
    reject("No data found in local storage.");
  });
}

export function getUserInfo() {
  const jwt = localStorage.getItem("jwt");
  if (!jwt) {
    return null;
  }
  const base64Url = jwt.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const payload = JSON.parse(atob(base64));
  return payload;
}

export function isAdmin(userInfo: any) {
  return userInfo && userInfo.roles.includes("Admin");
}

export function hasRole(userInfo: any, role: string) {
  return userInfo && userInfo.roles.includes(role);
}
