import React from "react";
import UpcomingEventsWidget from "./widgets/UpcomingEventsWidget";
import AnnouncementsWidget from "./widgets/AnnouncementsWidget";
import { getUserInfo } from "../../utils/API";
import PublicHomePage from "./PublicHomePage";

class HomePage extends React.Component {
  render() {
    const userInfo = getUserInfo();
    if (!userInfo) {
      return <PublicHomePage />;
    }
    return (
      <div className="container mt-2">
        <h1>Home</h1>
        <div className="d-flex flex-column gap-2 flex-wrap">
          <AnnouncementsWidget />
          <UpcomingEventsWidget />
        </div>
      </div>
    );
  }
}

export default HomePage;