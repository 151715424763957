import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../home/HomePage";
import NavBar from "./NavBar";
import DynamicPageComponent from "../pages/DynamicPage";
import { ProtectedRoute } from "../../utils/PrivateRoute";
import SettingsPage from "../settings/SettingsPage";
import CalendarPage from "../calendar/CalendarPage";
import AddCalendarEventPage from "../calendar/AddCalendarEvent";
import CalendarEventPage from "../calendar/CalendarEvent";
import ResourcesPage from "../resources/ResourcesPage";
import MeritBadgesPage from "../resources/merit-badges/MeritBadgesPage";
import MeritBadgeDetailsPage from "../resources/merit-badges/MeritBadgeDetails";
import AnnouncementsPage from "../announcements/AnnouncementsPage";
import PhotoAlbumsPage from "../resources/photos/PhotosPage";
import VideosPage from "../resources/videos/VideosPage";
import RosterPage from "../resources/roster/RosterPage";
import AccountPage from "../account/AccountPage";
import NotFoundPage from "./NotFoundPage";
import LoginPage from "../login/LoginPage";
import AdminPage from "../admin/ResourcesPage";
import ManageDynamicPages from "../admin/pages/ManageDynamicPages";
import CreateDynamicPage from "../admin/pages/CreateDynamicPage";
import EditDynamicPage from "../admin/pages/EditDynamicPage";
import EditCalendarEventPage from "../calendar/EditCalendarEvent";
import ManageUsers from "../admin/users/UsersPage";
import ManageHomePage from "../admin/home-page/ManageHomePage";
import { useDispatch } from "react-redux";
import { fetchAnnouncements, fetchCalendar, fetchDynamicPages, fetchHomePageSections, fetchMeritBadges, fetchPhotoAlbums, fetchPublicDynamicPages, fetchPublicHomePageSections, fetchPublicUpcomingEvent } from "../../store/slices";

function MainPage({ theme, onThemeUpdate }: { theme: "light" | "dark"; onThemeUpdate: (theme: "light" | "dark") => void }) {
  const dispatch = useDispatch();
  const isLoggedIn = localStorage.getItem("jwt") !== null;

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchCalendar() as any);
      dispatch(fetchAnnouncements() as any);
      dispatch(fetchDynamicPages() as any);
      dispatch(fetchMeritBadges() as any);
      dispatch(fetchPhotoAlbums() as any);
      dispatch(fetchHomePageSections() as any);
    } else {
      dispatch(fetchPublicDynamicPages() as any);
      dispatch(fetchPublicHomePageSections() as any);
      dispatch(fetchPublicUpcomingEvent() as any);
    }
  }, [isLoggedIn, dispatch]);

  return (
    <BrowserRouter>
      <NavBar />
      <div style={{ height: "86vh", overflow: "auto" }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/pages/:slug" element={<DynamicPageComponent />} />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <SettingsPage theme={theme} onThemeUpdate={onThemeUpdate} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <AccountPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/pages"
            element={
              <ProtectedRoute>
                <ManageDynamicPages />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/users"
            element={
              <ProtectedRoute>
                <ManageUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/home-page"
            element={
              <ProtectedRoute>
                <ManageHomePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/pages/:slug"
            element={
              <ProtectedRoute>
                <EditDynamicPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/pages/create"
            element={
              <ProtectedRoute>
                <CreateDynamicPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/calendar"
            element={
              <ProtectedRoute>
                <CalendarPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/calendar/events/add"
            element={
              <ProtectedRoute>
                <AddCalendarEventPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/calendar/events/:id"
            element={
              <ProtectedRoute>
                <CalendarEventPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/calendar/events/:id/edit"
            element={
              <ProtectedRoute>
                <EditCalendarEventPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/announcements"
            element={
              <ProtectedRoute>
                <AnnouncementsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/resources"
            element={
              <ProtectedRoute>
                <ResourcesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/resources/photos"
            element={
              <ProtectedRoute>
                <PhotoAlbumsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/resources/videos"
            element={
              <ProtectedRoute>
                <VideosPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/resources/roster"
            element={
              <ProtectedRoute>
                <RosterPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/resources/merit-badges"
            element={
              <ProtectedRoute>
                <MeritBadgesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/resources/merit-badges/:id"
            element={
              <ProtectedRoute>
                <MeritBadgeDetailsPage />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}


export default MainPage;