import { useState, useEffect } from "react";
import { callAPI } from "../../../utils/API";
import { Alert, TextField } from "@mui/material";
import "./PhotosPage.css";
import { PhotoAlbum } from "../../../store/types";
import { useSelector } from "react-redux";
import BSACircularProgress from "../../home/widgets/BSACircularProgress";

function PhotoAlbumCard({ photoAlbum }: { photoAlbum: PhotoAlbum }) {
  return (
    <a
      className="photo-album"
      target="_blank"
      rel="noreferrer"
      href={photoAlbum.album_url}
      style={{
        transition: "none 0s ease 0s",
      }}
    >
      <div className="photo-album-image" style={{
        backgroundImage: `url(${photoAlbum.preview_img})`,
      }}></div>
      <div className="photo-album-details">
        <div className="photo-album-controls">
          <span onClick={() => null}>Edit</span> &nbsp;·&nbsp; <span onClick={() => null}>Delete</span>
        </div>
        <div className="photo-album-name" dir="auto">{photoAlbum.name}</div>
        <div className="photo-album-info">{photoAlbum.num_items} items &nbsp;·&nbsp; {new Date(photoAlbum.date).toLocaleDateString('en-US', { timeZone: 'UTC' })} &nbsp;·&nbsp; {photoAlbum.author}</div>
      </div>
    </a>
  );
}

function PhotoAlbumsPage() {
  const { data, isFetching, isError } = useSelector((state: any) => state.photoAlbums);
  const [searchTerm, setSearchTerm] = useState("");
  const photoAlbums = (data as PhotoAlbum[]).sort((a: PhotoAlbum, b: PhotoAlbum) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  }).filter((a) =>
    a.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isError) {
    return (
      <div className="container mt-2">
        <h1>Photos</h1>
        <Alert severity="error">Failed to load photo albums.</Alert>
      </div>
    );
  }

  if (isFetching) {
    return (
      <div className="container mt-2">
        <h1>Photos</h1>
        <div className="progress-container">
          <BSACircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-2">
      <h1>Photos</h1>
      <TextField
        label="Search Photos"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="my-3 row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-xl-6 row-cols-lg-6">
        {photoAlbums.map((album) => (
          <PhotoAlbumCard photoAlbum={album} key={album.id} />
        ))}
      </div>
    </div>
  );
}

export default PhotoAlbumsPage;